// import logo from './logo.svg';
// import './App.css';
import {  Routes, Route} from "react-router-dom"
import './Main.css';
import Register from "./routes/Register";
import { Cart } from "./routes/Cart";
import Explore from "./routes/Explore";
import Home from "./routes/Home";
import Menus from './routes/Menus';
import Login from "./routes/Login";
import IsAuth from "./middleware/IsAuth";
import IsGuest from "./middleware/IsGuest";
import MyStore from "./routes/MyStore";
import MyStoreCreate from "./routes/MyStoreCreate";
import Account from "./routes/Account";
import IsStore from "./middleware/IsStore";
import AccountEdit from "./routes/AccountEdit";
import MyStoreAddProduct from "./routes/MyStoreAddProduct";
import { ProductView } from "./routes/ProductView";

function App() {
  return (
    <div className="App">
        <Routes>
            {/* PUBLIC  */}
            <Route path='/' element={ <Home/> }></Route>
            <Route path='/menus' element={ <Menus/> }></Route>
            <Route path='/p/:slugProduct' element={ <ProductView/> }></Route>
            <Route path='/explore' element={ <IsAuth> <Explore/> </IsAuth> }></Route>
            <Route path='/cart' element={ <Cart/> }></Route>
            <Route path='/account/edit' element={ <IsAuth> <AccountEdit/> </IsAuth> }></Route>
            <Route path='/account' element={ <IsAuth> <Account/> </IsAuth> }></Route>
            <Route path='/login' element={ <IsGuest> <Login/>  </IsGuest>}></Route>
            <Route path='/register' element={<IsGuest> <Register/> </IsGuest> }></Route>
            <Route path='/mystore' element={<IsAuth> <MyStore/> </IsAuth> }></Route>
            <Route path='/mystore/add-product' element={<IsAuth> <MyStoreAddProduct/> </IsAuth> }></Route>
            <Route path='/mystore/create' element={<IsAuth><IsStore> <MyStoreCreate/> </IsStore></IsAuth> }></Route>
        </Routes>
    </div>
  );
}

export default App;
