import React from 'react'
import NavbarBottom from '../components/NavbarBottom'
import NavbarTopSP from '../components/NavbarTopSP'

const Explore = () => {
  return (
    <div>
        <NavbarTopSP title={"Explore"}></NavbarTopSP>
        <div className='container py-2'>
            <div className='row'>
                <div className='my-3 col-md-3'>
                    <div className="card">
                        <img src="https://source.unsplash.com/random/?food" className="card-img-top explore-card-image" style={{}} alt="Pic Proudct " />
                        <div className="card-body">
                            <div className='card-title explore-card-title'>Nasi Goreng</div>
                            <div className='card-title explore-card-price'>Rp. 28.000</div>
                        </div>
                    </div>
                </div>

                <div className='my-3 col-md-3'>
                    <div className="card">
                        <img src="https://source.unsplash.com/random/?makanan" className="card-img-top explore-card-image" style={{}} alt="Pic Proudct " />
                        <div className="card-body">
                            <div className='card-title explore-card-title'>Nasi Goreng</div>
                            <div className='card-title explore-card-price'>Rp. 28.000</div>
                        </div>
                    </div>
                </div>

                <div className='my-3 col-md-3'>
                    <div className="card">
                        <img src="https://source.unsplash.com/random/?beverage" className="card-img-top explore-card-image" style={{}} alt="Pic Proudct " />
                        <div className="card-body">
                            <div className='card-title explore-card-title'>Nasi Goreng</div>
                            <div className='card-title explore-card-price'>Rp. 28.000</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NavbarBottom></NavbarBottom>
    </div>
  )
}

export default Explore