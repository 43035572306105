import React from 'react'
import { Link } from 'react-router-dom'

const MyStoreIdontHaveStore = () => {
  return (
    <div className='text-center' style={{ marginTop: '25vh'}}>
        <p>Kamu belum punya toko disini</p>
        <Link to="/mystore/create" className='btn btn-primary'>
            Buka toko
        </Link>
    </div>
  )
}

export default MyStoreIdontHaveStore