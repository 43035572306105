import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import NavbarTopSP from '../components/NavbarTopSP';
import checkIsKosong from '../functions/GlobalFunctions';

const MyStoreCreate = () => {
    const [StoreName, setStoreName] = useState([]);
    const [ErrorStoreName, setErrorStoreName] = useState([]);
    const [Address, setAddress] = useState([]);
    const [ErrorAddress, setErrorAddress] = useState([]);
    const [Phone, setPhone] = useState([]);
    const [ErrorPhone, setErrorPhone] = useState([]);
    const [Province, setProvince] = useState([]);
    const [ErrorProvince, setErrorProvince] = useState([]);
    const [Regency, setRegency] = useState([]);
    const [ErrorRegency, setErrorRegency] = useState([]);
    const [District, setDistrict] = useState([]);
    const [ErrorDistrict, setErrorDistrict] = useState([]);
    const nav = useNavigate();
    const isMounted = useRef();
    const [Spinner,setSpinner] = useState(true);
    const [DataProvinces, setDataProvinces] = useState([]);
    const [DataRegencies, setDataRegencies] = useState([]);
    const [DataDistricts, setDataDistricts] = useState([]);

    useEffect(() => {
        getProvinces();
        if (!isMounted.current) {
            return () => {
                // getProvinces();
            }
        } else {
            isMounted.current = true;
        }
    });

    
    // HANDLE
    const handleGetRegencies = (data) => {
        getRegenciesByProvinceId(data);
    }
    const handleGetDistricts = (data) => {
        getDistrictsByRegencyId(data);
    }

    const handleCancel = () => {
        nav(-1);
    }

    const handleSubmit = async () => {
        setSpinner(true);
        setErrorStoreName('')
        setErrorProvince('')
        setErrorRegency('')
        setErrorDistrict('')
        setErrorAddress('')
        setErrorPhone('')

        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {
            store_name: StoreName,
            province: Province,
            regency: Regency,
            district: District,
            address: Address,
            phone: Phone,
        }

        await axios.post(
            process.env.REACT_APP_API_URL+"api/mystore/create",
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                nav("/login")
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
                if (res.data.data) {
                    if (res.data.data.error) {
                        if (res.data.data.error.store_name) {
                            setErrorStoreName(res.data.data.error.store_name)
                        }
        
                        if (res.data.data.error.province) {
                            setErrorProvince(res.data.data.error.province)
                        }

                        if (res.data.data.error.regency) {
                            setErrorRegency(res.data.data.error.regency)
                        }

                        if (res.data.data.error.district) {
                            setErrorDistrict(res.data.data.error.district)
                        }

                        if (res.data.data.error.phone) {
                            setErrorAddress(res.data.data.error.address)
                        }

                        if (res.data.data.error.phone) {
                            setErrorPhone(res.data.data.error.phone)
                        }
                    }
                }
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }


    // FUNCTION
    const getProvinces = async () => {
        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {

        }

        await axios.get(
            process.env.REACT_APP_API_URL+"api/getProvinces",
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                setDataProvinces(res.data.data.reg_provinces)
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }

    const getRegenciesByProvinceId = async (data) => {
        if (checkIsKosong(data)) {
            alert(data);
            return false;
        }
        var province_id = data;
        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {

        }

        await axios.get(
            process.env.REACT_APP_API_URL+"api/getRegenciesByProvinceId/"+province_id,
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                setDataRegencies(res.data.data.reg_regencies)
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }

    const getDistrictsByRegencyId = async (data) => {
        var regency_id = data;
        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {

        }

        await axios.get(
            process.env.REACT_APP_API_URL+"api/getDistrictsByRegencyId/"+regency_id,
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                setDataDistricts(res.data.data.reg_districts)
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }
  return (
    <>
        <NavbarTopSP title="Create Store"></NavbarTopSP>
        <div className='container py-5 mb-5'>
            {
                Spinner === true 
                ?
                <Loader></Loader>
                :
                <div className='row'>
                    <div className='col'>
                        <div className='form-group my-3'>
                            <label>Store Name</label>
                            <input className='form-control' value={StoreName} onChange={ (e) => { setStoreName(e.target.value)}}></input>
                            <span className='text-danger'>{ErrorStoreName}</span>
                        </div>
                        <div className='form-group mb-3'>
                            <label>Province</label>
                            <select className='form-control' value={Province} onChange={  (e) =>  {
                                setProvince(e.target.value); handleGetRegencies(e.target.value);
                                }} >
                                <option value={''}>== SELECT PROVINCE ==</option>
                                {
                                    DataProvinces.map((v,i) => (
                                        <option value={v.id}> {v.name} </option>
                                    ))
                                }
                            </select>
                            <span className='text-danger'>{ErrorProvince}</span>
                        </div>
                        <div className='form-group mb-3'>
                            <label>Regency</label>
                            <select className='form-control' value={Regency} onChange={ (e) => { setRegency(e.target.value); handleGetDistricts(e.target.value);}}>
                                <option defaultValue={0}>== SELECT REGENCY ==</option>
                                {
                                    DataRegencies.map((v,i) => (
                                        <option value={v.id}> {v.name} </option>
                                    ))
                                }
                            </select>
                            <span className='text-danger'>{ErrorRegency}</span>
                        </div>
                        <div className='form-group mb-3'>
                            <label>District</label>
                            <select className='form-control' value={District} onChange={ (e) => { setDistrict(e.target.value); }}>
                                <option defaultValue={''}>== SELECT DISTRICT ==</option>
                                {
                                    DataDistricts.map((v,i) => (
                                        <option value={v.id}> {v.name} </option>
                                    ))
                                }
                            </select>
                            <span className='text-danger'>{ErrorDistrict}</span>
                        </div>
                        <div className='form-group mb-3'>
                            <label>Address</label>
                            <input className='form-control' value={Address} onChange={ (e) => { setAddress(e.target.value)}}></input>
                            <span className='text-danger'>{ErrorAddress}</span>
                        </div>
                        <div className='form-group mb-3'>
                            <label>Phone</label>
                            <input className='form-control' value={Phone} onChange={ (e) => { setPhone(e.target.value)}}></input>
                            <span className='text-danger'>{ErrorPhone}</span>
                        </div>
                        <div className='form-group mb-3'>
                            <button type='button' className='btn btn-primary-outline mx-1' onClick={handleCancel}>Cancel</button>
                            <button type='button' className='btn btn-primary mx-1' onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    </>
  )
}

export default MyStoreCreate