import React from 'react'
import NavbarBottom from '../components/NavbarBottom'
import NavbarV2 from '../components/NavbarV2'
import ProductNew from '../components/ProductNew'
import ProductTerlaris from '../components/ProductTerlaris'
import WelcomeMessage from '../components/WelcomeMessage'

const Home = () => {
  return (
    <div>
        <NavbarV2 page_title={"Home"}></NavbarV2>
        <div className="container py-5 mb-5">
            <WelcomeMessage></WelcomeMessage>
            <ProductTerlaris></ProductTerlaris>
            <div className="my-5"></div>
            <ProductNew></ProductNew>
        </div>
        <NavbarBottom></NavbarBottom>
    </div>
  )
}

export default Home