import React from 'react'
import { Link } from 'react-router-dom'
import "../Main.css"

const NavbarV2 = (props) => {
  return (
    <div>
        <nav className="navbar sticky-top navbar-light bg-light">
            <div className="container justify-content-center">
                <Link to="/cart" className='navbar-brand'>
                    {process.env.REACT_APP_NAME}
                </Link>
            </div>
        </nav>
        <nav className="navbar sticky-top navbar-light bg-light">
            <div className="container">
                <Link to="#" className='navbar-brand'>
                    {process.env.REACT_APP_NAME}
                </Link>
                <Link to="#" className='navbar-brand'>
                    {props.page_title}
                </Link>
                <Link to="/menus" className='navbar-brand'>
                    <i className='fa fa-bars icon-mbl'></i>
                </Link>
            </div>
        </nav>
    </div>
  )
}

export default NavbarV2