import React from 'react'
import MyStoreIdontHaveStore from '../components/MyStoreIdontHaveStore'
import MyStoreMenus from '../components/MyStoreMenus'
import MyStoreProducts from '../components/MyStoreProducts'
import MyStoreRecomendedForYou from '../components/MyStoreRecomendedForYou'
import NavbarBottom from '../components/NavbarBottom'
import NavbarTopSP from '../components/NavbarTopSP'
import ProductTerlaris from '../components/ProductTerlaris'

const MyStore = () => {
    if (localStorage.getItem('is_store') !== '1') {
        return (
            <>
                <NavbarTopSP title='My Store'></NavbarTopSP>
                <MyStoreIdontHaveStore></MyStoreIdontHaveStore>
            </>
        )
    } else {
        return (
          <div>
              <NavbarTopSP title='My Store'></NavbarTopSP>
              <MyStoreMenus></MyStoreMenus>
      
              <div className='container py-2'>
                  <ProductTerlaris></ProductTerlaris>
                  
                  <MyStoreRecomendedForYou></MyStoreRecomendedForYou> 
              </div>
      
              <NavbarBottom></NavbarBottom>
          </div>
        )
    }
}

export default MyStore