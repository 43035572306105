import React from 'react'

const Loader = () => {
  return (
    <div style={{marginTop:"25vh"}}>
        <div class="row  text-center align-items-center">
            <div class="spinner-border mx-auto" role="status" aria-hidden="true" style={{ height: "3rem", width: "3rem"}}></div>
            <br></br>
            <strong>Loading...</strong>
        </div>
    </div>
  )
}

export default Loader