import React from 'react'
import NavbarBottom from '../components/NavbarBottom'
import NavbarTopSP from '../components/NavbarTopSP'

export const Cart = () => {
  return (
    <div>
        <NavbarTopSP title={"Cart"}></NavbarTopSP>
        <div className='container py-2 mb-5'>
            <div className='row my-3 shadow'>
                <div className='col-12 row py-3'>
                    <div className='col-md-4 col-4'>
                        <div className='card border-0'>
                            <img src="https://source.unsplash.com/random/?nasi goreng" className="card-img-top rounded cart-card-image" style={{}} alt="Pic Proudct " />
                        </div>
                    </div>
                    <div className='col-md-4 col-4'>
                        <p className='card-title cart-card-title'>Nasi Goreng</p>
                        <p className='card-title cart-card-price'>Rp. 28.000</p>
                    </div>
                    <div className='col-md-4 col-12 text-end align-middle'>
                        <p className='card-title cart-card-price'>
                            <button className='btn btn-sm'><i className='fa fa-minus-circle icon-mbl text-danger'></i></button>
                                <input type={'text'} className='cart-card-input text-center' defaultValue={1}/>
                            <button className='btn btn-sm'><i className='fa fa-plus-circle icon-mbl text-success'></i></button>
                        </p>
                    </div>
                </div>
            </div>

            <div className='row my-3 shadow'>
                <div className='col-12 row py-3'>
                    <div className='col-md-4 col-4'>
                        <div className='card border-0'>
                            <img src="https://source.unsplash.com/random/?burger" className="card-img-top rounded cart-card-image" style={{}} alt="Pic Proudct " />
                        </div>
                    </div>
                    <div className='col-md-4 col-4'>
                        <p className='card-title cart-card-title'>Burger</p>
                        <p className='card-title cart-card-price'>Rp. 28.000</p>
                    </div>
                    <div className='col-md-4 col-12 text-end'>
                        <p className='card-title cart-card-price'>
                            <button className='btn btn-sm'><i className='fa fa-minus-circle icon-mbl text-danger'></i></button>
                                <input type={'text'} className='cart-card-input text-center' defaultValue={1}/>
                            <button className='btn btn-sm'><i className='fa fa-plus-circle icon-mbl text-success'></i></button>
                        </p>
                    </div>
                </div>
            </div>

            <div className='row my-3 shadow'>
                <div className='col-12 row py-3'>
                    <div className='col-md-4 col-4'>
                        <div className='card border-0'>
                            <img src="https://source.unsplash.com/random/?Ice Cream" className="card-img-top rounded cart-card-image" style={{}} alt="Pic Proudct " />
                        </div>
                    </div>
                    <div className='col-md-4 col-4'>
                        <p className='card-title cart-card-title'>Ice Cream</p>
                        <p className='card-title cart-card-price'>Rp. 28.000</p>
                    </div>
                    <div className=' col-md-4 col-12 text-end'>
                        <p className='card-title cart-card-price'>
                            <button className='btn btn-sm'><i className='fa fa-minus-circle icon-mbl text-danger'></i></button>
                                <input type={'text'} className='cart-card-input text-center' defaultValue={1}/>
                            <button className='btn btn-sm'><i className='fa fa-plus-circle icon-mbl text-success'></i></button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <NavbarBottom></NavbarBottom>
    </div>
  )
}
