import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "../Main.css"

const NavbarTopProductView = (props) => {
    const nav = useNavigate();
    
  return (
    <div className='sticky-top'>
        <nav className="navbar  sticky-top navbar-light bg-light">
            <div className="container">
                <Link to="#" onClick={ () => nav(-1)} className='navbar-brand'>
                    <i className='fa fa-arrow-left icon-mbl'></i>
                </Link>
                <div className='d-flex align-items-center'>
                    <input className='form-control me-3' placeholder='Search'></input> <i className='btn btn-primary fa fa-search icon-mbl'></i>
                </div>
                <Link to="/menus" className='navbar-brand'>
                    <i className='fa fa-bars icon-mbl'> {nav}</i>
                </Link>
            </div>
        </nav>
    </div>
  )
}

export default NavbarTopProductView