import React from 'react'

const BtnSignOut = (props) => {
  return (
    <div className='navbar-brand'>
        <i className='fa fa-sign-out-alt icon-mbl'></i>
            <br/>
        <span>Sign Out</span>
    </div>
  )
}

export default BtnSignOut