import  { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const IsGuest = (props) => {
    const nav = useNavigate();
    const isMounted = useRef();

    useEffect(()=> {
        if (!isMounted.current) {
            if (localStorage.getItem("accessToken")) {
                nav("/");
            }
        }
        isMounted.current = true;
    })
    
  return props.children
}

export default IsGuest