import React from 'react'
import checkIsKosong from '../functions/GlobalFunctions'

const WelcomeMessage = () => {
  return (
    <div>
        <div class="alert alert-info alert-dismissible fade show" role="alert">
            Hi, <strong>{ !checkIsKosong( localStorage.getItem('accessToken') ) ? localStorage.getItem('name') : ''}</strong> selamat datang di aya mart, selamat berbelanja. 
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
  )
}

export default WelcomeMessage