import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import checkIsKosong from '../functions/GlobalFunctions';
import BtnSignOut from './BtnSignOut'

const MenuApp = () => {

    const nav = useNavigate();
    const handleSignOut = (e) => {
        localStorage.clear();
        nav("/login")
    }
  return (
    <div className='row justify-content-center text-center'>
        <div className='col-lg-2 col-3 my-2'>
            <Link to="/stores" className='navbar-brand'>
                <i className='fa fa-store-alt icon-mbl'></i>
                <br/>
                <span>Store</span>
            </Link>
        </div>
        <div className='col-lg-2 col-3 my-2'>
            <Link to="/stores" className='navbar-brand'>
                <i className='fa fa-heart icon-mbl'> </i>
                <br/>
                <span>Favorites</span>
            </Link>
        </div>
        <div className='col-lg-2 col-3 my-2'>
            <Link to="/mystore" className='navbar-brand'>
                <i className='fa fa-store icon-mbl'></i>
                <br/>
                <span>My Store</span>
            </Link>
        </div>
        <div className='col-lg-2 col-3 my-2'>
            <Link to="/mystore" className='navbar-brand'>
                <i className='fa fa-boxes icon-mbl'></i>
                <br/>
                <span>My Products</span>
            </Link>
        </div>
        <div className='col-lg-2 col-3 my-2'>
            <Link to="/stores" className='navbar-brand'>
                <i className='fa fa-history icon-mbl'></i>
                <br/>
                <span>History</span>
            </Link>
        </div>

        {
            checkIsKosong(localStorage.getItem('accessToken'))
            ? 
            ''
            :
            <div className='col-lg-2 col-3 my-2' onClick={handleSignOut}>
                <BtnSignOut > </BtnSignOut>
            </div>
        }
       
    </div>
  )
}

export default MenuApp