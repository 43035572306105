import React from 'react'

const Rating = (props) => {

     
    if ( props.rate === '4') {
        return (
          <div className='d-flex align-items-center'>
              <span className='fs-5'>{props.rate}</span> 
              <span>
                  <i className='fa fa-star fa-fw fs-6 text-warning'></i>
                  <i className='fa fa-star fa-fw fs-6 text-warning'></i>
                  <i className='fa fa-star fa-fw fs-6 text-warning'></i>
                  <i className='fa fa-star fa-fw fs-6 text-warning'></i>
                  <i className='far fa-star fa-fw fs-6 text-warning'></i>
              </span> 
          </div>
        )
    }
}

export default Rating