import React, { useEffect, useRef, useState } from 'react'
import NavbarTopSP from '../components/NavbarTopSP'
import Loader from '../components/Loader'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const AccountEdit = () => {
    const isMounted = useRef();
    const nav = useNavigate();
    const [Name,setName] = useState([]);
    const [Email,setEmail] = useState([]);
    const [Address,setAddress] = useState([]);
    const [Phone,setPhone] = useState([]);
    const [Since,setSince] = useState([]);
    const [Spinner,setSpinner] = useState(true);

    useEffect(() => { 
        getUserAccount();
        if (!isMounted.current) {
            return () => {
                // getUserAccount();
            } 
        } else {
            isMounted.current = true; 
        }
    },[])


    // HANDLE
    const handleBack = () => {
        nav(-1);
    }

    const handleSubmit = () => {
        saveAccount();
    }

    const getUserAccount = async () => {
        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {

        }

        await axios.post(
            process.env.REACT_APP_API_URL+"api/account/getUserAccount",
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                setName(res.data.data.user.name);
                setEmail(res.data.data.user.email);
                setAddress(res.data.data.user.address);
                setPhone(res.data.data.user.phone);
                setSince(res.data.data.user.since);
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }

    const saveAccount = async () => {
        setSpinner(true);
        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {
            Email: Email,
            Name: Name,
            Phone: Phone,
            Address: Address,
        }

        await axios.post(
            process.env.REACT_APP_API_URL+"api/account/saveAccount",
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                alert(res.data.message);
                setName(res.data.data.user.name);
                setEmail(res.data.data.user.email);
                setAddress(res.data.data.user.address);
                setPhone(res.data.data.user.phone);
                setSince(res.data.data.user.since);
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }
  return (
    <div>
        <NavbarTopSP title={"Edit Account"}></NavbarTopSP>
        <div className='container py-5 mb-5'>
            {
                Spinner === true 
                ?
                <Loader></Loader>
                :
                <div className='row justify-content-center'>
                    <div className='col-md-10'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='row justify-content-between'>
                                    <div className='col-6'>Informasi Pribadi</div >
                                    <div className='col-6 text-end'>
                                        <Link to="/account/edit" className='navbar-brand'> <i className='fa fa-pencil'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='form-group mb-2'>
                                    <label>Full Name</label>
                                    <input className='form-control' value={Name} type={'text'} onChange={ (e) => {
                                        setName(e.target.value);
                                    }}></input>
                                </div>
                                <div className='form-group mb-2'>
                                    <label>Email</label>
                                    <input readOnly className='form-control' type={'email'} value={Email} onChange={ (e) => {
                                        setEmail(e.target.value);
                                    }}></input>
                                </div>
                                <div className='form-group mb-2'>
                                    <label>Address</label>
                                    <textarea className='form-control' type={'text'} value={Address} onChange={ (e) => {
                                        setAddress(e.target.value);
                                    }}></textarea>
                                </div>
                                <div className='form-group mb-2'>
                                    <label>Phone</label>
                                    <input className='form-control' type={'text'} value={Phone} onChange={ (e) => {
                                        setPhone(e.target.value);
                                    }}></input>
                                </div>
                                <div className='form-group mb-2'>
                                    <label>Member since</label>
                                    <input readOnly className='form-control' type={'text'} value={Since} ></input>
                                </div>
                            </div>
                            <div className='card-footer row'>
                                <button className='btn btn-md me-3 col-2 col-md-2' onClick={handleBack}>Cancel</button>
                                <button className='btn btn-md btn-primary col-2 col-md-2' onClick={handleSubmit}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
  )
}

export default AccountEdit