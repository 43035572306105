import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import MyStoreLatestProduct from '../components/MyStoreLatestProduct';
import NavbarTopSP from '../components/NavbarTopSP';
import ErrorLabel from './ErrorLabel';

const MyStoreAddProduct = () => {
    const [Spinner,setSpinner] = useState(true);
    const [ProductName, setProductName] = useState([]);
    const [ErrorProductName, setErrorProductName] = useState([]);
    const [Price, setPrice] = useState([]);
    const [ErrorPrice, setErrorPrice] = useState([]);
    const [Description, setDescription] = useState([]);
    const [ErrorDescription, setErrorDescription] = useState([]);
    const [DataLatestProduct, setDataLatestProduct] = useState([]);
    const nav = useNavigate();
    const isMounted = useRef();

    useEffect(() => {
        getLatestProduct();
    },[]);

    const handleReset = () => {
        setProductName('');
        setPrice('');
        setDescription('');
    }

    const handleSave = () => {
        saveProduct();
    }

    const saveProduct = async () => {
        setSpinner(true)
        setErrorProductName('')
        setErrorPrice('')
        setErrorDescription('')

        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {
            ProductName: ProductName,
            Price: Price,
            Description: Description,
        }

        await axios.post(
            process.env.REACT_APP_API_URL+"api/mystore/addProduct",
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                alert(res.data.message);
                handleReset();
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
                if (res.data.data) {
                    if (res.data.data.error) {
                        if (res.data.data.error.ProductName) {
                            setErrorProductName(res.data.data.error.ProductName)
                        }
        
                        if (res.data.data.error.Price) {
                            setErrorPrice(res.data.data.error.Price)
                        }

                        if (res.data.data.error.Description) {
                            setErrorDescription(res.data.data.error.Description)
                        }
                    }
                }
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }

    const getLatestProduct = async () => {
        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {
        }

        await axios.post(
            process.env.REACT_APP_API_URL+"api/mystore/getLatestProduct",
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                setDataLatestProduct(res.data.data.latest_product)
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
                if (res.data.data) {
                    if (res.data.data.error) {
                        if (res.data.data.error.ProductName) {
                            setErrorProductName(res.data.data.error.ProductName)
                        }
        
                        if (res.data.data.error.Price) {
                            setErrorPrice(res.data.data.error.Price)
                        }

                        if (res.data.data.error.Description) {
                            setErrorDescription(res.data.data.error.Description)
                        }
                    }
                }
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }
  return (

    <div>
        <NavbarTopSP title="Add Product"></NavbarTopSP>
        <div className='container py-5 mb-5'>
            {
                Spinner === true 
                ?
                    <Loader></Loader>
                :
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group mb-3'>
                                <label>Product</label>
                                <input className='form-control' value={ProductName} placeholder={"Example: Rokok Pesta 12 batang"} onChange={ (e) => setProductName(e.target.value) }></input>
                                <ErrorLabel pesan={ErrorProductName}></ErrorLabel>
                            </div>
                            <div className='form-group mb-3'>
                                <label>Price</label>
                                <input className='form-control' type={"number"} value={Price} placeholder={"Example: 13000"} onChange={ (e) => setPrice(e.target.value) }></input>
                                <ErrorLabel pesan={ErrorPrice}></ErrorLabel>
                            </div>
                            <div className='form-group mb-3'>
                                <label>Description</label>
                                <textarea className='form-control' type={"number"} value={Description} placeholder={"Example: Rokok pesta 12 batang + korek"} onChange={ (e) => setDescription(e.target.value) }></textarea>
                                <ErrorLabel pesan={ErrorDescription}></ErrorLabel>
                            </div>
                            <div className='form-group mb-3'>
                                <button type='button' className='btn btn-md btn-outline-secondary me-3' onClick={handleReset}>Reset</button>
                                <button type='button' className='btn btn-md btn-outline-primary'onClick={handleSave} >Save</button>
                            </div>
                        </div>

                        <div className='col-md-6 row'>
                            <h5>Latest Product</h5>
                            {
                                DataLatestProduct.map( (v,i) => (
                                    <div className='col-md-6'> 
                                        <MyStoreLatestProduct ProductName={v.ProductName} Price={v.Price}></MyStoreLatestProduct>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
            }
        </div>
    </div>
  )
}

export default MyStoreAddProduct