import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';

const Login = () => {
    const [Email,setEmail] = useState([]);
    const [Password,setPassword] = useState([]);
    const [ErrorEmail,setErrorEmail] = useState([]);
    const [ErrorPassword,setErrorPassword] = useState([]);
    const [BtnLogin,setBtnLogin] = useState('Login');
    const nav = useNavigate();
    
    const handleLogin = async () => {
        setBtnLogin('Loading...')
        setErrorEmail('');
        setErrorPassword('');
        const headers = {
            headers: {
                // email: sessionStorage.getItem("email"),
                // 'Authorization': 'Bearer '+sessionStorage.getItem("accessToken")
            }
        };
        const dataBody = {
          email: Email,
          password: Password,
        };
        await axios.post(process.env.REACT_APP_API_URL+"api/login", dataBody,headers)
        .then((res)=> {
            setBtnLogin('Login')

            if (res.data.code === "200") {
                localStorage.setItem("accessToken", res.data.data.user_info.accessToken);
                localStorage.setItem("email", res.data.data.user_info.email);
                localStorage.setItem("id", res.data.data.user_info.id);
                localStorage.setItem("name", res.data.data.user_info.name);
                localStorage.setItem("is_store", res.data.data.is_store);

                nav("/");
            } else if (res.data.code === "666") {
              alert(res.data.message);
            } else {
              alert(res.data.message)
              if (res.data.data) {
                  if (res.data.data.error) {
                    if (res.data.data.error.email) {
                      setErrorEmail(res.data.data.error.email)
                    }
    
                    if (res.data.data.error.password) {
                        setErrorPassword(res.data.data.error.password)
                    }
                  }
              }
            }
        }).catch((err) => {
            alert(err);
        });
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    }

  return (
    <div className=''>
        <div className='container py-2'>
            <div className='row'>
                <div className='col-12'>
                    <div className='display-1 text-center'>
                        {process.env.REACT_APP_NAME}
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-6' style={{ marginTop: '10vh'}}>
                    <div className='card'>
                        <div className='card-body'>
                                <h1 className="h3 mb-3 fw-normal">Login</h1>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={Email} onChange={ (e) => setEmail(e.target.value)} onKeyPress={handleKeyPress}/>
                                    <label htmlFor="floatingInput">Email address</label>
                                    <span className='text-danger'>{ErrorEmail}</span>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={Password} onChange={ (e) => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>
                                    <label htmlFor="floatingPassword">Password</label>
                                    <span className='text-danger'>{ErrorPassword}</span>
                                </div>
                                <div className="checkbox mb-3">
                                    <label>
                                    <input type="checkbox" defaultValue="remember-me" /> Remember me
                                    </label>
                                </div>
                                <button className="w-100 btn btn-lg btn-primary" type="button" onClick={handleLogin}>{BtnLogin}</button>
                                <p className="mt-5 mb-3 text-muted text-center">
                                    <Link to="/register">Register</Link>
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login