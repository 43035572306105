import React from 'react'
import { Link } from 'react-router-dom'

const NavbarBottomProductViews = () => {
  return (
    <div className='fixed-bottom'>
        <nav className="navbar navbar-light bg-light">
            <div className="container">
                <button className='btn btn-outline-primary w-50 py-3'>
                 ADD TO CART
                </button>
                <button className='btn btn-outline-primary w-50 py-3'> BUY NOW</button>
            </div>
        </nav>
    </div>
  )
}

export default NavbarBottomProductViews