import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "../Main.css"

const NavbarTopSP = (props) => {
    const nav = useNavigate();
    
  return (
    <div className='sticky-top'>
        {/* <nav className="navbar navbar-light bg-light">
            <div className="container justify-content-center">
                <Link to="/cart" className='navbar-brand'>
                    {process.env.REACT_APP_NAME}
                </Link>
            </div>
        </nav> */}
        <nav className="navbar  sticky-top navbar-light bg-light">
            <div className="container">
                <Link to="#" onClick={ () => nav(-1)} className='navbar-brand'>
                    <i className='fa fa-arrow-left icon-mbl'></i>
                </Link>
                <Link to="#" className='navbar-brand'>
                    {props.title}
                </Link>
                <Link to="/menus" className='navbar-brand'>
                    <i className='fa fa-bars icon-mbl'> {nav}</i>
                </Link>
            </div>
        </nav>
    </div>
  )
}

export default NavbarTopSP