import React from 'react'
import { Link } from 'react-router-dom';
import "../Main.css";

const MyStoreMenus = () => {
  return (
    <div className='container px-5'>
        <div className="row d-flex flex-row flex-nowrap wrapScroll ">
            <div className='col-lg-2 col-3 my-2'>
                <Link to="/mystore/add-product" className='navbar-brand'>
                    <br/>
                    <span>Add Product</span>
                </Link>
            </div>

        </div>
    </div>
  )
}

export default MyStoreMenus