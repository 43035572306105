import React, { useEffect, useRef, useState } from 'react'
import NavbarTopSP from '../components/NavbarTopSP'
import Loader from '../components/Loader'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import checkIsKosong from '../functions/GlobalFunctions';

const Account = () => {
    const isMounted = useRef();
    const nav = useNavigate();
    const [Name,setName] = useState([]);
    const [Email,setEmail] = useState([]);
    const [Phone,setPhone] = useState([]);
    const [Since,setSince] = useState([]);
    const [Spinner,setSpinner] = useState(true);

    useEffect(() => { 
        getUserAccount();
        if (!isMounted.current) {
            return () => {
            } 
        } 
        isMounted.current = true; 
    },[])

    const getUserAccount = async () => {
        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {

        }

        await axios.post(
            process.env.REACT_APP_API_URL+"api/account/getUserAccount",
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                setName(res.data.data.user.name);
                setEmail(res.data.data.user.email);
                setPhone(res.data.data.user.phone);
                setSince(res.data.data.user.since);
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }
  return (
    <div>
        <NavbarTopSP title={"Account"}></NavbarTopSP>
        <div className='container py-5 mb-5'>
            {
                Spinner === true 
                ?
                <Loader></Loader>
                :
                <div className='row justify-content-center'>
                    <div className='col-md-10'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='row justify-content-between'>
                                    <div className='col-6'>Informasi Pribadi</div >
                                    <div className='col-6 text-end'>
                                        <Link to="/account/edit" className='navbar-brand'> <i className='fa fa-pencil'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='form-group mb-2'>
                                    <p className='fs-3'>{ Name }</p>
                                </div>
                                <div className='form-group mb-2'>
                                    <p className='fs-5'>{ Email }</p>
                                </div>
                                <div className='form-group mb-2'>
                                    <p className='fs-5'>{ Phone }</p>
                                </div>
                                <div className='form-group mb-2'>
                                    <p className='fs-5'> { !checkIsKosong(Since) ?'Member Since: ' +Since:'' }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
  )
}

export default Account