
const checkIsKosong = (props) => {
    // return (
    //     alert(props)
    // )

    if(typeof(props) == 'number' || typeof(props) == 'boolean')
    { 
        return false; 
    }
    if(typeof(props) == 'undefined' || props === null)
    {
        return true; 
    }
    if(typeof(props.length) != 'undefined')
    {
        return props.length === 0;
    }
    var count = 0;
    for(var i in props)
    {
        if(props.hasOwnProperty(i))
        {
        count ++;
        }
    }
    return count === 0;
}

export default checkIsKosong