import React from 'react'
import MenuApp from '../components/MenuApp'
import NavbarBottom from '../components/NavbarBottom'
import NavbarV2 from '../components/NavbarV2'

const Menus = () => {
  return (
    <div>
        <NavbarV2></NavbarV2>
        <div className='container py-5'>
            <MenuApp></MenuApp>
        </div>
        <NavbarBottom></NavbarBottom>
    </div>
  )
}

export default Menus