import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "../Main.css";
import Loader from './Loader';

const ProductNew = () => {
    const [Spinner,setSpinner] = useState(true);
    const nav = useNavigate();
    const [DataProducts, setDataProducts] = useState([]);
    
    useEffect(() => {
        getNewProduct();
    },[]);

    const handleLink = (e) => {
        var slug = e.currentTarget.getAttribute('data_slug');
        nav(slug)
    }

    const getNewProduct = async () => {
        const options = {
            headers: {
                user_id: localStorage.getItem("id"),
                accessToken: localStorage.getItem("accessToken"),
                "Authorization": "Bearer "+localStorage.getItem("accessToken"),
            }
        }
        const body = {

        }

        await axios.post(
            process.env.REACT_APP_API_URL+"api/getNewProduct",
            body,
            options
        )
        .then((res)=>{
            setSpinner(false)
            if (res.data.code === "200") {
                setDataProducts(res.data.data.products)
            } else if (res.data.code === "666") {
                alert(res.data.message);
                localStorage.clear()
                nav("/login")
            } else {
                alert(res.data.message);
            }
        })
        .catch((err) => {
            if (err.request.status === 401) {
                alert("Invalid token!");
                localStorage.clear();
                nav("/login")
            } else {
                alert(err)
            }
        });
    }

    if ( Spinner === true ) {
        return (
            <Loader></Loader>
        )
    } else {
        return (
          <>
              <h5>Produk Terbaru</h5>
              <div className="row d-flex flex-row flex-nowrap wrapScroll ">
                {
                    DataProducts.map((v,i) => (
                        <div className="col-lg-3 col-md-5 col-sm-7 col-9" key={i} onClick={handleLink} data_slug={"/p/"+v.slug}>
                            <div className="card">
                                <img src="https://ik.imagekit.io/dcjlghyytp1/b710752fb09b751215e4baa78c30a527?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                <h5 className="card-title cropText">{v.ProductName}</h5>
                                <p className="card-text noWrapScroll fs-5 fw-bold text-primary">{v.PriceDisplay}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
                    
      
                  <div className="col-lg-3 col-md-5 col-sm-7 col-9" onClick={handleLink} data_slug="test">
                  <div className="card">
                      <img src="https://ik.imagekit.io/dcjlghyytp1/b710752fb09b751215e4baa78c30a527?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
                      <div className="card-body">
                      <h5 className="card-title">Card title</h5>
                      <p className="card-text noWrapScroll">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      <a href="#" className="btn btn-primary">Add</a>
                      </div>
                  </div>
                  </div>
      
              </div>
          </>
        )
    }
}

export default ProductNew