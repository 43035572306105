import  { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import checkIsKosong from '../functions/GlobalFunctions';

const IsAuth = (props) => {
    const nav = useNavigate();
    const isMounted = useRef();

    useEffect(()=> {
        if (!isMounted.current) {
            if ( checkIsKosong(localStorage.getItem("accessToken")) ) {
                nav("/login");
            }
        }
        isMounted.current = true;
    })
    
  return props.children
}

export default IsAuth