import React from 'react'
import BtnAddToCart from './BtnAddToCart'

const MyStoreLatestProduct = (props) => {
  return (
    <div className="card">
        <img src="https://ik.imagekit.io/dcjlghyytp1/58268b5c1d1cdd65f4dcc62b2c6fb50e?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
        <div className="card-body">
        <h5 className="card-title">{props.ProductName}</h5>
        <h5 className="card-title">{props.Price}</h5>
        <p className="card-text noWrapScroll">{props.Description}.</p>
        <BtnAddToCart></BtnAddToCart>
        </div>
    </div>
  )
}

export default MyStoreLatestProduct