import React from 'react'
import NavbarBottom from '../components/NavbarBottom'
import NavbarBottomProductViews from '../components/NavbarBottomProductViews'
import NavbarTopProductView from '../components/NavbarTopProductView'
import NavbarTopSP from '../components/NavbarTopSP'
import Rating from '../components/Rating'

export const ProductView = () => {
  return (
    <div>
        <NavbarTopProductView></NavbarTopProductView>
        <div className='container py-2 mb-5'>
            <div className='row my-3 shadow'>
                <div className='col-md-6 row py-3'>
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                            <img src="https://source.unsplash.com/random/?burger" className="d-block w-100 objectFitCover carousel-product-view" alt="..." />
                            </div>
                            <div className="carousel-item">
                            <img src="https://source.unsplash.com/random/?burger makanan" className="d-block w-100 objectFitCover carousel-product-view" alt="..." />
                            </div>
                            <div className="carousel-item">
                            <img src="https://source.unsplash.com/random/?burger food " className="d-block w-100 objectFitCover carousel-product-view" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className='col-md-6 '>
                    <div className='row'>
                        <p className='fs-4 mt-3'>Burger bikin senang</p>
                    </div>
                   
                    <div className='row mb-3'>
                        <div className='col-md-4 mb-3 d-flex'>
                            <i className='far fa-heart fa-fw fs-3 text-danger'></i> <span>100 menyukai</span>
                        </div>
                        <div className='col-md-4 mb-3 d-flex'>
                            <i className='far fa-comments fa-fw fs-3'></i> <span> 100 komentar</span>
                        </div>
                        <div className='col-md-4 mb-3 d-flex align-items-center'>
                            <Rating rate={"4"}></Rating>
                        </div>
                    </div>
                    <div>
                        <p className='fs-5 fw-bold text-primary border p-3 rounded'>Rp.30.000.00</p>
                    </div>
                    <div className='row'>
                        <p>Description</p>
                        <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta, eros ac dignissim porta, risus orci malesuada massa, quis rutrum enim nunc vitae turpis. Donec in nisi id sem lobortis aliquet nec vitae purus. Aliquam erat volutpat. Vestibulum commodo sollicitudin magna in suscipit. Phasellus condimentum tellus quis arcu venenatis varius. Fusce ligula erat, dapibus vel nunc at, porta gravida magna.</p>
                    </div>
                </div>
            </div>

           
        </div>
        <NavbarBottomProductViews></NavbarBottomProductViews>
    </div>
  )
}
