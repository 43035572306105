import { React } from 'react'
import {Link, useLocation} from 'react-router-dom'

const NavbarBottom = () => {
    const location = useLocation();
  return (
    <div className='fixed-bottom'>
        <nav className="navbar navbar-light bg-light">
            <div className="container">
                <Link to="/" className='navbar-brand'>
                    <i className={`fa fa-home icon-mbl ${location.pathname == '/'? 'text-primary':''}`}></i>
                </Link>
                <Link to="/explore" className='navbar-brand'>
                    <i className={`fa fa-globe icon-mbl ${location.pathname == '/explore'? 'text-primary':''}`}></i>
                </Link>
                <Link to="/cart" className='navbar-brand'>
                    <i className={`fa fa-shopping-cart icon-mbl ${location.pathname == '/cart'? 'text-primary':''}`}></i>
                </Link>
                <Link to="/account" className='navbar-brand'>
                    <i className='fa fa-user icon-mbl'></i> 
                </Link>
            </div>
        </nav>
    </div>
  )
}

export default NavbarBottom