import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

const Register = () => {
    const [Email,setEmail] = useState([]);
    const [Password,setPassword] = useState([]);
    const [Name,setName] = useState([]);
    const [ErrorName,setErrorName] = useState([]);
    const [ErrorEmail,setErrorEmail] = useState([]);
    const [ErrorPassword,setErrorPassword] = useState([]);

    const handleRegister = async () => {
        setErrorEmail('');
        setErrorPassword('');
        setErrorName('');
        const headers = {
            headers: {
                // email: sessionStorage.getItem("email"),
                // 'Authorization': 'Bearer '+sessionStorage.getItem("accessToken")
            }
        };
        const dataBody = {
          name: Name,
          email: Email,
          password: Password,
        };
        await axios.post(process.env.REACT_APP_API_URL+"api/register", dataBody,headers)
        .then((res)=> {
            if (res.data.code === "200") {
            } else if (res.data.code === "666") {
              alert(res.data.message);
            } else {
              alert(res.data.message)
              if (res.data.data.error) {
                if (res.data.data.error.email) {
                  setErrorEmail(res.data.data.error.email)
                }

                if (res.data.data.error.password) {
                    setErrorPassword(res.data.data.error.password)
                }

                if (res.data.data.error.name) {
                    alert('dor');
                    setErrorName(res.data.data.error.name)
                }
              }
            }
        }).catch((err) => {
            alert(err);
        });
    }

  return (
    <div className=''>
        <div className='container py-2'>
            <div className='row'>
                <div className='col-12'>
                    <div className='display-1 text-center'>
                        {process.env.REACT_APP_NAME}
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-6' style={{ marginTop: '10vh'}}>
                    <div className='card'>
                        <div className='card-body'>
                                <h1 className="h3 mb-3 fw-normal">Register</h1>
                                <div class="form-floating mt-3 mb-3">
                                    <input type="text" className="form-control" id="inputName" name="name" placeholder="name@example.com"  value={Name} onChange={ (e) => setName(e.target.value)} />
                                    <label for="inputName">Full Name</label>
                                    <span className='text-danger'>{ErrorName}</span>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={Email} onChange={ (e) => setEmail(e.target.value)} />
                                    <label htmlFor="floatingInput">Email address</label>
                                    <span className='text-danger'>{ErrorEmail}</span>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={Password} onChange={ (e) => setPassword(e.target.value)} />
                                    <label htmlFor="floatingPassword">Password</label>
                                    <span className='text-danger'>{ErrorPassword}</span>
                                </div>
                                <div className="checkbox mb-3">
                                    <label>
                                    <input type="checkbox" defaultValue="remember-me" /> Remember me
                                    </label>
                                </div>
                                <button className="w-100 btn btn-lg btn-primary" type="button" onClick={handleRegister}>Register</button>
                                <p className="mt-5 mb-3 text-muted text-center">
                                    <Link to="/login">Login</Link>
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register