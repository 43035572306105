import React from 'react'
import "../Main.css";

const MyStoreRecomendedForYou = () => {
  return (
    <>
        <h5>Produk Terlaris</h5>
        <div className="row d-flex flex-row flex-nowrap wrapScroll ">
            <div className="col-lg-3 col-md-5 col-sm-7 col-9">
            <div className="card">
                <img src="https://ik.imagekit.io/dcjlghyytp1/58268b5c1d1cdd65f4dcc62b2c6fb50e?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
                <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text noWrapScroll">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Add</a>
                </div>
            </div>
            </div>
            
            <div className="col-lg-3 col-md-5 col-sm-7 col-9">
            <div className="card">
                <img src="https://ik.imagekit.io/dcjlghyytp1/58268b5c1d1cdd65f4dcc62b2c6fb50e?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
                <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text noWrapScroll">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Add</a>
                </div>
            </div>
            </div>
            
            <div className="col-lg-3 col-md-5 col-sm-7 col-9">
                <div className="card">
                    <img src="https://ik.imagekit.io/dcjlghyytp1/58268b5c1d1cdd65f4dcc62b2c6fb50e?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
                    <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text noWrapScroll">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <a href="#" className="btn btn-primary">Add</a>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-md-5 col-sm-7 col-9">
            <div className="card">
                <img src="https://ik.imagekit.io/dcjlghyytp1/58268b5c1d1cdd65f4dcc62b2c6fb50e?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
                <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text noWrapScroll">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Add</a>
                </div>
            </div>
            </div>

            <div className="col-lg-3 col-md-5 col-sm-7 col-9">
            <div className="card">
                <img src="https://ik.imagekit.io/dcjlghyytp1/58268b5c1d1cdd65f4dcc62b2c6fb50e?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
                <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text noWrapScroll">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Add</a>
                </div>
            </div>
            </div>

            <div className="col-lg-3 col-md-5 col-sm-7 col-9">
            <div className="card">
                <img src="https://ik.imagekit.io/dcjlghyytp1/58268b5c1d1cdd65f4dcc62b2c6fb50e?tr=f-auto,w-145,dpr-1" className="card-img-top" alt="..."/>
                <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text noWrapScroll">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Add</a>
                </div>
            </div>
            </div>

        </div>
    </>
  )
}

export default MyStoreRecomendedForYou